<template>

	<section class="domain-overview">

		<div v-if="!domainData[domain] || domainData[domain] === 'loading'">
			<loader></loader>
		</div>
		<div v-else-if="isError(domainData[domain])">
			<div class="alert alert-danger">{{ domainData[domain].message }}</div>
		</div>
		<div v-else-if="domainData[domain]">
			<div class="row mb-4">
				<div class="col-auto text-center">
					<h4 class="text-capitalize rounded px-2 py-1 mb-1" :class="[badgeAvailability[domainData[domain].availability]]">{{ labelAvailability[domainData[domain].availability] || domainData[domain].availability }}</h4>
					<small v-if="domainData[domain].availability === 'registered' && domainData[domain].registrar.name" class="text-muted">
						at
						<a v-if="domainData[domain].registrar.url" target="_blank" :href="domainData[domain].registrar.url" rel="nofollow">{{ truncateString(domainData[domain].registrar.name, 33) }}</a>
						<span v-else>{{ truncateString(domainData[domain].registrar.name, 33) }}</span>
					</small>
				</div>
				<div class="col">
					<span v-for="status in domainInfo.status" :key="status" class="badge bg-light text-secondary m-1" :class="{'bg-red-100 text-red-500': status.toLowerCase() === 'pendingdelete', 'text-orange-400': status.startsWith('server')}">{{ status }}</span>
				</div>
			</div>

			<div v-if="isStaff" class="card bg-danger-lighter mb-4">
				<div class="card-body px-2 py-1">
					<div class="row">
						<div class="col-6 col-lg-4">
							<p class="mb-1">Monitoring: <strong>by {{ Math.floor((domainInfo.m || 0) / 5) }} people</strong></p>
						</div>
						<div class="col-6 col-lg-4">
							<a :href="`https://namebio.com/${domain}`" target="_blank" rel="noopener noreferer">NameBio</a>:
							<span v-if="domainInfo.si" class="cursor-pointer" @click="salesImport">✅</span>
							<span v-else>
								<button class="btn btn-sm btn-primary me-2" @click="salesImport">Import</button>
								<button class="btn btn-sm btn-light" @click="salesImported">Imported</button>
							</span>

							<span v-if="states.salesImport === 'loading'" class="spinner-border spinner-border-sm ms-2"></span>
						</div>
						<div class="col-6 col-lg-4">
							<p class="mb-0">
								<a :href="`https://www.similarweb.com/website/${domain}/`" target="_blank">Similarweb rank: <strong v-if="domainInfo.similar_web_rank">{{ domainInfo.similar_web_rank.toLocaleString() }}</strong><small v-else>-</small></a>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div v-if="domainInfo.availability === 'registered' && (domainData[domain].lists || related.ns.total)" class="mb-3">
				<router-link v-for="list in domainData[domain].lists" :key="`d-${domain}-l-${list.slug}`" :to="`/collections/${list.slug}`" class="link-to-related color-indigo mb-2">
					<span class="link-icon">🗂️</span>
					{{ list.name }}
				</router-link>

				<router-link v-if="related.ns.total && isPro" :to="`/domains-database?ns=${JSON.stringify(domainInfo.ns)}`" class="link-to-related color-violet mb-2">
					<span class="link-icon">{{ related.ns.total }}</span>
					Same NS: <small>{{ maxItems(domainInfo.ns, 2) }}</small>
				</router-link>
				<span v-else-if="related.ns.total" @click="goToRelated('')" class="link-to-related color-violet cursor-pointer mb-2">
					<span class="link-icon">{{ related.ns.total }}</span>
					Same NS: <small>{{ maxItems(domainInfo.ns, 2) }}</small>
				</span>
			</div>

			<div v-if="domainInfo.availability === 'registered' && domainInfo.status.includes('redemptionPeriod')" class="bg-primary-lighter rounded border p-3 mb-5">
				<p class="mb-1"><strong>{{ domain }}</strong> was not renewed and entered expiration phase. Owner has the option to renew it.</p>
				<p class="mb-0"><strong>Monitor the domain to be notified when it becomes available.</strong></p>
			</div>
			<div v-else-if="domainInfo.availability === 'registered' && domainInfo.status.includes('pendingDelete')" class="bg-primary-lighter rounded border p-3 mb-5">
				<div class="row align-items-center">
					<div class="col">
						<p class="lead mb-0"><strong>{{ domain }}</strong> was not renewed and expired. It will become available for registration in a few days.</p>
					</div>
					<div v-if="domainInfo.services.backorder.length" class="col-auto">
						<div class="dropdown">
							<button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Backorder</button>
							<div class="dropdown-menu dropdown-menu-right">
								<a v-for="registrar in domainInfo.services.backorder" :key="registrar.name" :href="registrar.url" class="dropdown-item" target="_blank"><span class="text-muted">at</span> {{ registrar.name }}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else-if="domainInfo.availability === 'registered' && domainInfo.services.forSale.length" class="bg-primary-lighter rounded border border-primary-lighter p-3 mb-5">
				<div class="row align-items-center">
					<div class="col">
						<p class="lead mb-0"><strong>{{ domain }}</strong> is listed for sale.</p>
					</div>
					<div class="col-auto">
						<a v-for="sale in domainInfo.services.forSale" :key="sale.name" :href="sale.url" class="btn btn-outline-primary mx-1" target="_blank">Buy at {{ sale.name }}</a>
					</div>
				</div>
			</div>

			<div v-if="lastPrivateNote" class="bg-warning-lighter p-3 rounded mb-4">
				<p class="mb-2">{{ lastPrivateNote.data }}</p>

				<div class="row">
					<div class="col">
						<small class="text-muted">{{ lastPrivateNote.created_at | dateToNow }}</small>
					</div>
					<div class="col-auto">
						<router-link :to="`/${domain}/history`">View all notes →</router-link>
					</div>
				</div>
			</div>
			<div v-else-if="lastNote" class="bg-cyan-50 px-3 py-2 rounded mb-4">
				<router-link :to="`/${domain}/history`" class="float-end text-dark">View all history →</router-link>
				<small class="text-muted">{{ lastNote.created_at | dateToNow }}</small> <span v-html="enrichContent(lastNote.data.text || lastNote.data)"></span>
			</div>

			<div v-if="domainData[domain].availability === 'registered'" class="bg-light rounded py-3 mb-4 position-relative">
				<h6 class="subtitle position-absolute bg-light rounded d-inline-block py-1 px-2 text-muted ms-2" style="top: -12px"><small>Important dates</small></h6>

				<div class="row align-items-center text-center">
					<div class="col">
						<p class="mb-2 lead">
							<time v-if="domainData[domain].dates.created" :datetime="domainData[domain].dates.created">{{ (new Date(domainData[domain].dates.created)).toLocaleDateString() }}</time>
							<i v-if="!domainData[domain].dates.created" class="text-muted"><small>unknown</small></i>
						</p>
						<p class="text-uppercase text-muted mb-0">Created {{ formatDistanceToNowStrict(new Date(domainData[domain].dates.created), { addSuffix: true }) }}</p>
					</div>
					<div class="col d-none d-lg-block">
						<p class="mb-2 lead">
							<time v-if="domainData[domain].dates.updated" :datetime="domainData[domain].dates.updated">{{ (new Date(domainData[domain].dates.updated)).toLocaleDateString() }}</time>
							<i v-if="!domainData[domain].dates.updated" class="text-muted"><small>unknown</small></i>
						</p>
						<p class="text-uppercase text-muted mb-0">Updated</p>
					</div>
					<div class="col">
						<p class="mb-2 lead">
							<time v-if="domainData[domain].dates.expiry" :datetime="domainData[domain].dates.expiry">{{ (new Date(domainData[domain].dates.expiry)).toLocaleDateString() }}</time>
							<i v-if="!domainData[domain].dates.expiry" class="text-muted"><small>unknown</small></i>
						</p>
						<p v-if="Number.isInteger(domainData[domain].dates.expiryDays) && domainData[domain].dates.expiryDays >= 0" class="text-uppercase text-muted mb-0"><span :class="{'badge bg-orange-100 text-orange-500': domainData[domain].dates.expiryDays < 31}">Expires {{ formatDistanceToNowStrict(new Date(domainData[domain].dates.expiry), { addSuffix: true }) }}</span></p>
						<p v-else-if="Number.isInteger(domainData[domain].dates.expiryDays) && domainData[domain].dates.expiryDays < 0" class="text-uppercase text-muted mb-0"><span class="badge bg-orange-100 text-red-500">Expired {{ formatDistanceToNowStrict(new Date(domainData[domain].dates.expiry), { addSuffix: true }) }}</span></p>
						<p v-else class="text-uppercase text-muted mb-0">Expires</p>
					</div>
				</div>
			</div>

			<!-- <div v-if="domainInfo.availability === 'registered'" class="pro-box rounded py-1 mb-4 position-relative">
				<h6 class="subtitle position-absolute rounded d-inline-block py-1 px-2 text-primary ms-2 cursor-pointer" style="top: -16px; background-color: #fae8ff" data-bs-toggle="modal" data-bs-target="#modal-plans"><small>Related domains</small></h6>
 
				<div class="row gx-2 text-center">
					<div class="col">
						<button class="row align-items-center mx-1" :class="{disabled: !domainInfo.registrant.organization && !domainInfo.registrant.name}">
							<p class="text-uppercase text-muted mb-1">Same owner</p>

							<h5 class="mb-0 text-primary">
								<span v-if="!related.registrant.available">-</span>
								<span v-else-if="related.registrant.loading">..</span>
								<span v-else>{{ related.registrant.total | numStat }}</span>
							</h5>

							<p class="mb-0"><small>{{ domainInfo.registrant.organization || domainInfo.registrant.name || 'No owner info' }}</small></p>
						</button>
					</div>
					<div class="col">
						<button class="row align-items-center mx-0" :class="{disabled: !domainInfo.ns.length}" @click="goToRelated(`ns=${JSON.stringify(domainInfo.ns)}`)">
							<p class="text-uppercase text-muted mb-1">Same Name Servers</p>

							<h5 class="mb-0 text-primary">
								<span v-if="!related.ns.available">-</span>
								<span v-else-if="related.ns.loading">..</span>
								<span v-else>{{ related.ns.total | numStat }}</span>
							</h5>

							<p class="mb-0">
								<small v-if="domainInfo.ns.length">{{ maxItems(domainInfo.ns, 2) }}</small>
								<small v-else>No NS</small>
							</p>

							<p v-if="related.ns.total && domainInfo.ns[0] && domainInfo.ns[0].endsWith('.ns.cloudflare.com')" class="mb-0"><small class="badge bg-info-lighter text-info">ℹ️ Likely same owner</small></p>
							<p v-else-if="related.ns.total && (domainInfo.ns.join(',').includes('.dan.hosting') || domainInfo.ns.join(',').includes('.verify.hn'))" class="mb-0"><small class="badge bg-info-lighter text-info">ℹ️ Same owner</small></p>
						</button>
					</div>
				</div>
			</div> -->

			<div v-if="domainData[domain].availability === 'registered'" class="bg-light rounded mb-4 position-relative">
				<div v-if="!domainData[domain].detailed" class="spinner-border spinner-border-sm text-secondary position-absolute mt-2" style="right: 2rem; z-index: 5" role="status"></div>
				<h6 class="subtitle position-absolute bg-light rounded d-inline-block py-1 px-2 text-muted ms-2" style="top: -12px"><small>Domain owner</small></h6>

				<div class="row align-items-center">
					<div class="col-auto py-3">
						<img
							:src="getPicture(domainData[domain].registrant.email, domain)"
							@error="$event.target.src = 'https://files.layered.market/neutral-2.png'"
							width="60"
							class="rounded ms-3"
							alt="Registrant"
						/>
					</div>
					<div class="col py-2">
						<p class="mb-1 lead">
							<router-link v-if="related.registrant.total && isPro" :to="`/domains-database?registrant_organization=${encodeURIComponent(domainInfo.registrant.organization.trim())}`" title="See more domains with same owner">
								<span v-html="uniqueValues([domainData[domain].registrant.name, domainData[domain].registrant.organization], 'Anonymous owner')"></span>
								<span class="badge rounded-pill bg-primary-lighter text-primary ms-1 animate__animated animate__heartBeat">{{ related.registrant.total }}</span>
							</router-link>
							<span v-else-if="related.registrant.total" @click="goToRelated('')" class="cursor-pointer" title="See more domains with same owner">
								<span v-html="uniqueValues([domainData[domain].registrant.name, domainData[domain].registrant.organization], 'Anonymous owner')"></span>
								<span class="badge rounded-pill bg-primary-lighter text-primary ms-1 animate__animated animate__heartBeat">{{ related.registrant.total }}</span>
							</span>
							<span v-else v-html="uniqueValues([domainData[domain].registrant.name, domainData[domain].registrant.organization], 'Anonymous owner')"></span>
						</p>

						<p class="mb-1" v-html="'📍 ' + uniqueValues([domainData[domain].registrant.city, domainData[domain].registrant.stateOrProvince, domainData[domain].registrant.country], 'No location found')"></p>
						<p class="mb-0 text-break" v-html="'💬 ' + uniqueValues([domainData[domain].registrant.phone, domainData[domain].registrant.email], 'No contact info found')"></p>
					</div>
					<div v-if="(domainData[domain].registrant.city || domainData[domain].registrant.stateOrProvince || domainData[domain].registrant.country)" class="col-auto d-none d-lg-flex">
						<img :src="`https://maps.googleapis.com/maps/api/staticmap?center=${[domainData[domain].registrant.city, domainData[domain].registrant.stateOrProvince, domainData[domain].registrant.country].filter(Boolean).join(', ')}&zoom=${[domainData[domain].registrant.city, domainData[domain].registrant.stateOrProvince, domainData[domain].registrant.country].filter(Boolean).length * 3}&size=170x130&key=AIzaSyCsteGqYhVM141VSrVKoNpA17G51g-HF8o&region=${domainData[domain].registrant.country}`" alt="Registrant location" class="rounded-right" />
					</div>
				</div>
			</div>

			<div v-if="domainData[domain].availability === 'registered'" class="bg-light rounded mb-3 position-relative px-3">
				<h6 class="subtitle position-absolute bg-light rounded d-inline-block py-1 px-2 text-muted" style="top: -12px"><small>Website info</small></h6>

				<div class="row align-items-center">
					<div v-if="domainInfo.website_info && (domainInfo.website_info.image || domainInfo.website_info.screenshot)" class="col-lg-5 text-center my-3">
						<div id="website-info-carousel" class="carousel slide" data-ride="false">
							<div class="carousel-inner">
								<div v-if="domainInfo.website_info.screenshot" class="carousel-item active">
									<img :src="domainInfo.website_info.screenshot" class="d-block w-100 bg-white" alt="Website screenshot">
								</div>
								<div v-if="domainInfo.website_info.image" class="carousel-item" :class="{active: !domainInfo.website_info.screenshot}">
									<img :src="domainInfo.website_info.image" class="d-block w-100 bg-white" alt="Website preview">
								</div>
							</div>
						</div>
						<p v-if="domainInfo.website_info.image && domainInfo.website_info.screenshot" class="mb-0 text-center">
							<span class="badge rounded-pill bg-secondary-light text-dark cursor-pointer mx-1" data-bs-target="#website-info-carousel" data-bs-slide-to="0">Screenshot</span>
							<span class="badge rounded-pill bg-secondary-light text-dark cursor-pointer mx-1" data-bs-target="#website-info-carousel" data-bs-slide-to="1">Preview pic</span>
						</p>
					</div>
					<div v-if="domainInfo.website_info" class="col mt-4 mb-2">
						<h6><a :href="domainInfo.website_info.url" target="_blank" class="text-dark" rel="nofollow">{{ domainInfo.website_info.title || domain }}</a></h6>
						<p v-if="domainInfo.website_info.description" class="mb-1">{{ domainInfo.website_info.description }}</p>
						<p class="text-dark mb-0"><small>
							<a :href="domainInfo.website_info.url" target="_blank" class="text-dark" rel="nofollow"><img v-if="domainInfo.website_info.favicon" :src="domainInfo.website_info.favicon" alt="logo" class="rounded" height="14" /> {{ domainInfo.website_info.url }}</a> &middot; 
							<a v-if="'archiveOrgSnapshot' in domainInfo.website_info && domainInfo.website_info.archiveOrgSnapshot" :href="domainInfo.website_info.archiveOrgSnapshot" class="text-dark" target="_blank">Archive.org snapshot <span class="text-muted">({{ formatArchiveOrgDate(domainInfo.website_info.archiveOrgSnapshot.split('/')[4]) }})</span></a>
							<span v-else-if="'archiveOrgSnapshot' in domainInfo.website_info" class="text-muted">No Archive.org snapshot</span>
							<a v-else :href="`https://web.archive.org/web/*/${domain}`" class="text-dark" target="_blank">View on Archive.org</a>
						</small></p>
					</div>
					<div v-else class="col my-4">
						<p class="mb-1">
							Loading website info
							<span class="spinner-border spinner-border-sm text-secondary" role="status"></span>
						</p>
						<p class="text-muted mb-0"><small>
							<a :href="`http://${domain}`" target="_blank" class="text-muted" rel="nofollow">http://{{ domain }}</a> &middot; 
							<a :href="`https://web.archive.org/web/*/${domain}`" class="text-muted" target="_blank">View on Archive.org</a>
						</small></p>
					</div>
				</div>
			</div>

			<div v-if="domainData[domain].availability === 'registered' && !domainData[domain].status.includes('inactive')" class="bg-light rounded p-3 mb-3">
				<div class="row my-2">
					<div class="col-lg-3">
						<span class="subtitle text-muted">Name Servers:</span>
					</div>
					<div class="col">
						<span v-for="ns in (domainData[domain].ns.length > 5 ? domainData[domain].ns.slice(0, 4) : domainData[domain].ns)" :key="ns" class="badge bg-secondary-light text-secondary text-lowercase me-1 mb-1">{{ ns }}</span>
						<router-link :to="`/${domain}/name-servers`" class="text-primary cursor-pointer"><small>{{ domainData[domain].ns.length > 5 ? `and ${domainData[domain].ns.length - 4} more` : 'NS details' }}</small></router-link>
					</div>
				</div>
				<div v-if="domainData[domain].services.dns.length" class="row my-2">
					<div class="col-lg-3">
						<span class="subtitle text-muted">DNS provider:</span>
					</div>
					<div class="col">
						<a v-for="dnsProvider in domainData[domain].services.dns" :key="dnsProvider.name" :href="dnsProvider.url" class="me-2" target="_blank" rel="nofollow">
							<img :src="dnsProvider.logo" width="14" class="rounded me-1" alt="DNS provider" />
							{{ dnsProvider.name }}
						</a>
					</div>
				</div>

				<div v-if="domainDnsRecordsUniq[domain] && domainDnsRecordsUniq[domain].length" class="row my-2">
					<div class="col-lg-3">
						<span class="subtitle text-muted">DNS Records:</span>
					</div>
					<div class="col">
						<span v-for="(dnsName, index) in domainDnsRecordsUniq[domain].slice(0, 5)" :key="index" class="badge bg-secondary-light text-secondary text-lowercase me-1 mb-1">{{ dnsName }}</span>
						<router-link v-if="Object.values(domainDnsRecords[domain]).flat().length > domainDnsRecordsUniq[domain].length" :to="`/${domain}/dns-records`" class="text-primary cursor-pointer"><small>and {{ Object.values(domainDnsRecords[domain]).flat().length - domainDnsRecordsUniq[domain].slice(0, 5).length }} more</small></router-link>
					</div>
				</div>
				<!--
				<div v-if="data.emailProvider" class="row my-2">
					<div class="col-3">
						<span class="subtitle text-muted">Email provider</span>
					</div>
					<div class="col">
						<a :href="data.emailProvider.url" target="_blank">
							<img :src="data.emailProvider.logo" width="14" class="rounded me-1" alt="Email provider" />
							{{ data.emailProvider.name }}
						</a>
					</div>
				</div>
				-->
			</div>

			<div v-if="domainData[domain].availability === 'available'" class="bg-light rounded p-3 mb-3">
				<div v-if="domainInfo.activityCount">
					<p class="lead">⚠️ <strong>{{ domain }}</strong> was registered before, but previous owner let it expire. <router-link :to="`/${domain}/history`" replace>See history</router-link></p>
				</div>
				<p v-else class="lead"><strong>{{ domain }}</strong> is available to register:</p>

				<p>Register now at:</p>
				<p>
					<a v-for="registrar in domainData[domain].services.buy" :key="registrar.name" :href="registrar.url" class="btn btn-sm btn-outline-primary m-1" target="_blank">{{ registrar.name }}</a>
				</p>
			</div>
		</div>
	</section>

</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import md5 from 'md5'
import { parse, formatDistanceToNowStrict } from 'date-fns'
import { debounce } from 'vue-debounce'

import { enrichContent, isPrivateRegistrant, truncateString } from "@/utils.js"
import domainsApi from "@/api.js"

import Loader from '@/components/Loader.vue'

export default {
	name: 'DomainOverview',
	props: ['domain'],
	components: { Loader },
	data() {
		return {
			states: {
				salesImport: 'idle',
			},
			labelAvailability: {
				registered: 'registered',
			},
			domainsList: [],
			related: {
				registrant: {
					available: false,
					loading: true,
					total: 0,
				},
				ns: {
					available: false,
					loading: true,
					total: 0,
				},
			},
			lastDomain: null,
		}
	},
	computed: {
		...mapState([ 'account', 'domainData', 'domainDnsRecords', 'domainDnsRecordsUniq', 'badgeAvailability', 'domainActivity', '$modalPlans' ]),
		...mapGetters([ 'auth', 'isStaff', 'isPro', 'isBot' ]),
		domainInfo() {
			return this.domainData[this.domain] || null
		},
		lastNote() {
			return (this.domainActivity[this.domain] || []).find(a => ['note', 'sale'].includes(a.type))
		},
		lastPrivateNote() {
			return (this.domainActivity[this.domain] || []).find(a => a.type === 'private-note')
		},
	},
	created() {
		//
	},
	methods: {
		formatDistanceToNowStrict,
		enrichContent,
		truncateString,

		isError(obj) {
			return obj instanceof Error
		},
		uniqueValues(values, defaultValue) {
			if (defaultValue) {
				defaultValue = `<i class="text-muted">${defaultValue || '..'}</i>`
			}

			values = [...new Set(values)]

			// remove item that is included in another item, ex "item 1, item 1 plus" => "item 1 plus"
			values = values.filter(item => !values.find(el => el && el !== item && el.includes(item)))

			return values.filter(Boolean).join(', ') || defaultValue
		},
		getPicture(email, domain) {
			const imgClearbit = `https://logo.clearbit.com/${domain}?size=50`

			return this.isEmail(email) ? `https://www.gravatar.com/avatar/${md5(email)}?s=50&d=${imgClearbit}` : imgClearbit
		},
		isEmail(email) {
			const emailRegex = /\S+@\S+\.\S+/
			return email && emailRegex.test(email.toLowerCase())
		},
		formatArchiveOrgDate(datetime) {
			const date = parse(datetime, 'yyyyMMddHHmmss', new Date())

			return date.toLocaleDateString()
		},

		salesImport() {
			this.states.salesImport = 'loading'

			domainsApi(`domain/${this.domain}/import-sales`).then(({ data }) => {
				this.domainData[this.domain].si = 1
				this.states.salesImport = 'loaded'

				if (data.imported) {
					Vue.toasted.success(`${this.domain} - ${data.message}`, {
						action: {
							text: 'View sales',
							href: `/${this.domain}/history`
						},
					})
				}
			})
		},
		salesImported() {
			this.states.salesImport = 'loading'

			domainsApi(`domain/${this.domain}/imported-sales`).then(() => {
				this.domainData[this.domain].si = 1
				this.states.salesImport = 'loaded'
			})
		},
		maxItems(array, max) {
			let txt = array.join(', ')

			if (array.length > max) {
				txt = `${array.slice(0, max - 1).join(', ')} + ${array.length - max + 1} more`
			}

			return txt
		},

		goToRelated(filters) {
			if (this.isPro) {
				this.$router.push(`/domains-database?${filters}`)
			} else {
				this.$store.dispatch('ev', {
					event: 'Related Popup',
					data: { notify: true }
				})
				this.$modalPlans.show()
			}
		},
		loadRelated() {
			if (this.domainInfo.availability !== 'registered') {
				return;
			}

			// don't load related domains if visitor is a bot
			if (this.isBot) {
				return;
			}

			this.related.registrant.loading = true
			this.related.ns.loading = true
			this.related.registrant.total = 0
			this.related.ns.total = 0

			// load same registrant
			const registrant = this.domainInfo.registrant.organization
			if (registrant && registrant.length && registrant.toLowerCase() != 'null' && !isPrivateRegistrant(registrant)) {
				this.related.registrant.available = true

				domainsApi(`domains/counts`, {
					params: { registrant_organization: registrant }
				}).then(({ data }) => {
					this.related.registrant.loading = false
					this.related.registrant.total = parseInt(data ? data - 1 : 0, 10)
				}, (error) => {
					this.related.registrant.loading = false
					this.related.registrant.total = 0

					if (this.isStaff) {
						Vue.toasted.error(`Couldn't load related registrant counts (${error.message})`)
					}
				})
			} else {
				this.related.registrant.available = false
			}

			// load same ns
			if (this.domainInfo.ns.length && this.auth) {
				this.related.ns.available = true
				this.related.ns.loading = true

				domainsApi(`domains/counts`, {
					params: {
						ns: JSON.stringify(this.domainInfo.ns),
					}
				}).then(({ data }) => {
					this.related.ns.loading = false
					this.related.ns.total = parseInt(data ? data - 1 : 0, 10)
				}, (error) => {
					this.related.ns.loading = false
					this.related.ns.total = 0

					if (this.isStaff) {
						Vue.toasted.error(`Couldn't load related NS counts (${error.message})`)
					}
				})
			} else {
				this.related.ns.available = false
			}

		},
		loadRelatedDebouncedShort: debounce(function() {
			this.loadRelated()
		}, 350),
		loadRelatedDebouncedLong: debounce(function() {
			this.loadRelated()
		}, 1250),
	},
	watch: {
		domainInfo(domain) {
			if (domain && this.lastDomain !== domain.domain) {
				this.lastDomain = domain.domain
				this.related.registrant.loading = true
				this.related.ns.loading = true

				this.isPro ? this.loadRelatedDebouncedShort() : this.loadRelatedDebouncedLong()
			}
		}
	}
}
</script>
