import Vue from 'vue'
import Vuex from 'vuex'
import { decode } from 'jsonwebtoken'
import { Modal, Offcanvas } from 'bootstrap'
import { uniq } from 'lodash'

import router from '../router'
import { identify, sendEvent } from "@/utils.js"
import domainsApi from '@/api.js'

Vue.use(Vuex)

const sendToMuchacho = (event, data = {}) => {
	let $distinct_id = localStorage.getItem('uid')

	if (!$distinct_id) {
		$distinct_id = crypto.randomUUID()
		localStorage.setItem('uid', $distinct_id)
	}

	const body = new URLSearchParams({
		$event: event,
		$distinct_id,
		$url: window.location.href,
	})

	for (const key in data) {
		if (data[key] !== undefined && data[key] !== null) {
			body.set(key, data[key])
		}
	}

	return fetch('https://start.layered.workers.dev/events/pr_05fc705b643a', {
		body,
		cache: 'no-cache',
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		method: 'POST',
		mode: 'no-cors',
	})
}

export default new Vuex.Store({
	state: {
		apiUrl: 'https://domain-api-qoenb5pwba-ew.a.run.app/',
		betas: JSON.parse(localStorage.getItem('dmns-beta') || '[]'),
		isMobile: window.innerWidth < 760,
		$modalPlan: null,
		$modalMonitoring: null,
		$modalPlans: null,
		$offcanvasMenu: null,

		// authenticated person data
		token: localStorage.getItem('auth-token') || null,
		authPerson: null,
		account: null,
		plans: {
			free: {
				name: 'Free',
				price: 0,
				domains: 1,
				lists: 5,
				api: false,
			},
			basic: {
				name: 'Basic',
				month: {
					id: 'price_1JYuVqJHKoI7hSyXGZUxZQxE',
					price: 9,
				},
				year: {
					id: 'price_1JYuVqJHKoI7hSyXKMrNJXmL',
					price: 90,
				},
				domains: 10,
				lists: 2,
				api: false,
			},
			pro: {
				name: 'Pro',
				month: {
					id: 'price_1JYuVzJHKoI7hSyXyHUXiynz',
					price: 29,
				},
				year: {
					id: 'price_1JYuVzJHKoI7hSyXnRMpWHUr',
					price: 290,
				},
				domains: 50,
				lists: 10,
				api: false,
			},
			business: {
				name: 'Business',
				month: {
					id: 'price_1JYuW3JHKoI7hSyXQhs0Z5er',
					price: 99,
				},
				year: {
					id: 'price_1JYuW3JHKoI7hSyXagNPBQcc',
					price: 990,
				},
				domains: 250,
				lists: 50,
				api: true,
			},
		},

		// domain data for auth person
		domainsMonitoring:	[],
		domainsMonitoringData:	[],
		domainsMonitoringLoaded: false,
		domainMonitoringSelected: null,

		// domain lists
		accountLists: [],
		publicLists: [],

		// domain data
		keywordStats:		{},
		domainData:			{},
		domainWhois:		{},
		domainDnsRecords:	{},
		domainDnsRecordsUniq: {},
		domainActivity:		{},
		domainEmails:		{},
		ips:				{},

		badgeAvailability: {
			available: 'bg-teal-100 text-green-500',
			registered: 'bg-blue-100 text-blue-500',
			reserved: 'bg-secondary-light text-secondary',
			unknown: 'bg-secondary-light text-black',
		},

		//stats
		stats: JSON.parse(localStorage.getItem('dmns-stats') || "{\"monitoring-whois-checks\": 10733134, \"notifications-sent\": 2833}"),
		statsTimer: null,

		people: {},
	},
	getters: {
		auth(state) {
			if (state.token && !state.authPerson) {
				state.authPerson = decode(state.token)
			}

			return state.authPerson
		},
		isStaff(state) {
			if (state.token) {
				const t = decode(state.token)

				return t.email.endsWith('@laye.red')
			}

			return false
		},
		isBetaTester(state) {
			if (state.token) {
				const t = decode(state.token)

				return t.email.endsWith('@laye.red') || t.email.endsWith('@dmns.app') || t.email.endsWith('@townweb.com')
			}

			return false
		},
		isPro(state) {
			return ['pro', 'business'].includes(state.account?.plan)
		},
		isBot() {
			const ua = window.navigator.userAgent.toLowerCase()

			return ua.includes('googlebot') || ua.includes('robot')
		},
	},
	mutations: {
		auth(state, token) {
			state.token = token
		},
		authLogout(state) {
			state.token = null
			state.authPerson = null
		},
		accountInfo(state, account) {
			state.account = account
		},
		accountListsAdd(state, lists) {
			state.accountLists.push(...lists)
		},
		accountListsDelete(state, list) {
			state.accountLists = state.accountLists.filter(l => l.id !== list.id)
		},
		publicListsAdd(state, lists) {
			state.publicLists.push(...lists)
		},
		domainIsLoading (state, domain) {
			if (!state.domainData[domain]) {
				state.domainData[domain] = 'loading'
			}
		},
		domainsMonitoringAdd(state, domains) {
			state.domainsMonitoringLoaded = true
			state.domainsMonitoringData.push(...domains)
			state.domainsMonitoring.push(...domains.map(d => d.domain))
		},
		domainsMonitoringRemove(state, domain) {
			state.domainsMonitoringData = state.domainsMonitoringData.filter(d => d.domain !== domain)
			state.domainsMonitoring = state.domainsMonitoring.filter(d => d !== domain)
		},
		selectMonitoredDomain(state, domainData) {
			state.domainMonitoringSelected = domainData
		},

		domainAddData (state, payload) {
			Vue.set(state.domainData, payload.domain, payload.data)

			//if (payload.data.detailed) {
				Vue.set(state.domainWhois, payload.domain, payload.data.whois)
			//}

			if (payload.data.availability && ['available', 'registered'].includes(payload.data.availability)) {
				let availability = payload.data.availability

				if (availability === 'registered' && payload.data.services.buy.length) {
					availability = 'forSale'
				}

				if (!state.keywordStats[payload.data.keyword]) {
					Vue.set(state.keywordStats, payload.data.keyword, {
						total: 0,
						available: [],
						registered: [],
						forSale: [],
					})
				}

				if (!state.keywordStats[payload.data.keyword][availability].includes(payload.data.tld)) {
					state.keywordStats[payload.data.keyword][availability].push(payload.data.tld)
					state.keywordStats[payload.data.keyword].total++
				}
			}
		},
		domainAddWhois (state, payload) {
			Vue.set(state.domainWhois, payload.domain, payload.data)
		},
		domainAddDnsRecords (state, payload) {
			Vue.set(state.domainDnsRecordsUniq, payload.domain, [])
			Vue.set(state.domainDnsRecords, payload.domain, payload.data)

			if (!(payload.data instanceof Error)) {
				const records = [ ...payload.data.A, ...payload.data.CNAME, ...payload.data.NS ]

				records.forEach(dnsRecord => {
					let name = dnsRecord.name

					if (name.endsWith('.')) {
						name = name.slice(0, name.length - 1)
					}

					if (!state.domainDnsRecordsUniq[payload.domain].includes(name)) {
						state.domainDnsRecordsUniq[payload.domain].push(name)
					}
				})
			}

		},
		domainAddActivity(state, payload) {
			Vue.set(state.domainActivity, payload.domain, payload.data)
		},
		domainUpdateActivity(state, payload) {
			state.domainActivity[payload.domain].unshift(payload.data)
		},
		domainAddWebsiteInfo(state, payload) {
			Vue.set(state.domainData[payload.domain], 'website_info', payload.data)
		},
		domainAddEmails(state, payload) {
			Vue.set(state.domainEmails, payload.domain, payload.data)
		},
		ipSet(state, payload) {
			Vue.set(state.ips, payload.ip, payload.loading ? false : payload )
		},

		setStats(state, stats) {
			clearInterval(state.statsTimer)

			localStorage.setItem('dmns-stats', JSON.stringify(stats))
			state.stats = stats

			state.statsTimer = setInterval(() => {
				state.stats['monitoring-whois-checks']++

				if (state.stats['monitoring-whois-checks'] % 15 === 0) {
					state.stats['notifications-sent']++
				}
			}, 4000)
		},

		prepareModals(state) {
			state.$modalMonitoring = new Modal(document.getElementById('modal-monitoring'))
			state.$modalPlan = new Modal(document.getElementById('modal-plan'))
			state.$modalPlans = new Modal(document.getElementById('modal-plans'))
			state.$offcanvasMenu = new Offcanvas(document.getElementById('offcanvasNavbar'))
		},

		addPeople(state, people) {
			people.forEach(person => {
				state.people[person.id] = person
			})
		},
		addPerson(state, person) {
			const newPeople = {}
			newPeople[person.id] = person
			state.people = { ...state.people, ...newPeople }
		},
	},
	actions: {
		ev: (store, { event, data }) => {
			sendEvent(event, data)

			// send to domains-api
			domainsApi.post('log', {
				event,
				...(data || {})
			})

			// send to Muchacho
			sendToMuchacho(event, {
				$user_id: store.getters.auth?.id,
				...data
			})
		},
		accountRegister: ({ commit, dispatch }, account) => {
			return new Promise((resolve, reject) => {
				domainsApi.post('account', account)
					.then(resp => {
						localStorage.setItem('auth-token', resp.data.token)
						commit('auth', resp.data.token)

						dispatch('domainsMonitoring')
						commit('accountInfo', resp.data.person)

						identify(resp.data.person)
						dispatch('ev', { event: 'Register' })

						resolve(resp)
					})
					.catch(err => {
						//commit(AUTH_ERROR, err)
						localStorage.removeItem('auth-token')
						reject(err)
					})
			})
		},
		authRequest: ({ commit, dispatch }, account) => {
			return new Promise((resolve, reject) => {
				domainsApi.post('account/login', account)
					.then(resp => {
						localStorage.setItem('auth-token', resp.data.token)
						commit('auth', resp.data.token)

						dispatch('domainsMonitoring')
						dispatch('accountInfo')
						resolve(resp)
					})
					.catch(err => {
						//commit(AUTH_ERROR, err)
						localStorage.removeItem('auth-token')
						reject(err)
					})
			})
		},
		authLogout: ({ commit }) => {
			return new Promise((resolve) => {
				commit('authLogout')
				localStorage.removeItem('auth-token')
				resolve()
				router.push('/')
			})
		},
		accountInfo: ({ state, commit }) => {
			return new Promise((resolve, reject) => {
				if (state.account === null && state.token) {
					commit('accountInfo', false)

					domainsApi.get('account/me').then(resp => {
						//tracker.setMetadata('plan', resp.data.plan)
						commit('accountInfo', resp.data)
						resolve(resp.data)
					}, reject)
				} else {
					resolve(state.account)
				}
			})
		},
		accountUpdate: ({ commit, state }, account) => {
			return new Promise((resolve, reject) => {
				domainsApi.put(`account/${state.authPerson.id}`, account)
					.then(resp => {
						commit('accountInfo', resp.data)
						Vue.toasted.show(`Account info is updated`)
						resolve(resp)
					})
					.catch(reject)
			})
		},

		// domain lists
		accountLists: ({ state, commit }) => {
			if (!state.accountLists.length && state.token) {
				domainsApi.get('lists?type=own').then(resp => {
					commit('accountListsAdd', resp.data)
				})
			}
		},
		listToggleDomain: (dz, payload) => {
			if (payload.list.domains.includes(payload.domain.id)) {
				domainsApi.delete(`lists/${payload.list.id}/domains/${payload.domain.id}`).then(() => {
					payload.list.domains = payload.list.domains.filter(d => d !== payload.domain.id)
					payload.list.domains_count--

					Vue.toasted.show(`${payload.domain.domain} is removed from "${payload.list.name}" list`, {
						action: { text: 'View list', push: `/collections/${payload.list.slug}` }
					})
				}, error => {
					Vue.toasted.error(`Error removing domain: ${error}`)
				})
			} else {
				domainsApi.post(`lists/${payload.list.id}/domains`, { domain_id: payload.domain.id }).then(() => {
					payload.list.domains.push(payload.domain.id)
					Vue.toasted.show(`${payload.domain.domain} is added to "${payload.list.name}" list`, {
						action: { text: 'View list', push: `/collections/${payload.list.slug}` }
					})
				}, error => {
					Vue.toasted.error(`Error adding domain: ${error}`)
				})
				
			}
		},
		loadPublicLists: ({ state, commit }) => {
			if (!state.publicLists.length) {
				domainsApi.get('lists?type=public&expand=person').then(resp => {
					commit('publicListsAdd', resp.data)
				})
			}
		},

		// domain monitoring
		domainsMonitoring: ({ commit, state }) => {
			if (state.token && !state.domainsMonitoringLoaded) {
				state.domainsMonitoringLoaded = true
				domainsApi.get(`account/following-domains`).then(({ data }) => {
					commit('domainsMonitoringAdd', data)
				})
			}
		},
		toggleDomainMonitor: ({ commit, state }, domain) => {
			return new Promise((resolve, reject) => {
				if (state.domainsMonitoring.includes(domain)) {
					domainsApi.delete(`domain/${domain}/monitoring`).then(() => {
						commit('domainsMonitoringRemove', domain)
						state.account.domains--

						Vue.toasted.show(`"${domain}" is removed from your monitoring list`, {
							action: { text: 'View list', push: '/domains-list?monitoring=1' }
						})

						resolve(domain)
					}, error => {
						Vue.toasted.show(`Error monitoring domain: ${error}`, { type: 'error' })
						reject(error)
					})
				} else {
					domainsApi.post(`domain/${domain}/monitoring`).then(({ data }) => {
						commit('domainsMonitoringAdd', [data])
						state.account.domains++

						Vue.toasted.show(`"${domain}" is added to your monitoring list, ${state.account.domains}/${state.account.domains_limit}`, {
							action: { text: 'View list', push: '/domains-list?monitoring=1' }
						})

						resolve(domain)
					}, error => {
						Vue.toasted.show(`Error monitoring domain: ${error}`, { type: 'error' })
						reject(error)
					})
				}
			})
			
		},
		updateSelectedDomainMonitor: ({ state }) => {
			domainsApi.put(`domain/${state.domainMonitoringSelected.domain}/monitoring/${state.domainMonitoringSelected.id}`, {
				types: JSON.stringify(state.domainMonitoringSelected.types),
				whois_priority: state.domainMonitoringSelected.whois_priority,
			}).then(() => {
				Vue.toasted.show(`Monitoring options are updated for "${state.domainMonitoringSelected.domain}"`)
			}, error => {
				Vue.toasted.show(`Error updating monitoring options: ${error}`, { type: 'error' })
			})
		},

		// domain data
		loadDomainData ({ commit, dispatch, state }, payload) {
			payload.domain = payload.domain.toLowerCase()

			return new Promise((resolve, reject) => {
				if (!state.domainData[payload.domain] || (payload.mode === 'detailed' && !state.domainData[payload.domain].detailed)) {
					//commit('domainIsLoading', payload.domain)

					domainsApi.get(`domain/${payload.domain}`, { params: { mode: payload.mode || 'normal' } }).then(({ data }) => {
						commit('domainAddData', { domain: payload.domain, data })
						resolve(data)
					}, error => {
						commit('domainAddData', { domain: payload.domain, data: new Error(error.response.data.error || error.response.statusText) })
						reject(error)
					})
				} else {
					resolve(state.domainData[payload.domain])
				}

				// store domain in browser storage
				if (payload.mode === 'detailed') {
					const domainHistory = JSON.parse(localStorage.getItem('domains') || '[]')
					if (!domainHistory.includes(payload.domain)) {
						domainHistory.unshift(payload.domain)
						localStorage.setItem('domains', JSON.stringify(domainHistory.slice(0, 20)))
					}

					dispatch('ev', { event: 'View domain', data: { feature: 'Domains', domain: payload.domain } })
				}
			})
		},
		loadDomainWhois ({ commit, state }, payload) {
			if (!state.domainWhois[payload.domain]) {
				domainsApi.get(`domain/${payload.domain}/whois`).then(({ data }) => {
					commit('domainAddWhois', { domain: payload.domain, data })
				}, error => {
					commit('domainAddWhois', { domain: payload.domain, data: new Error(error.response?.data?.error || error.response.statusText) })
				})
			}
		},
		loadDomainDnsRecords ({ commit, state }, payload) {
			return new Promise((resolve, reject) => {
				if (state.domainDnsRecords[payload.domain]) {
					resolve(state.domainDnsRecords[payload.domain])
				} else {
					domainsApi.get(`domain/${payload.domain}/dns-records`).then(({ data }) => {
						commit('domainAddDnsRecords', { domain: payload.domain, data })
						resolve(data)
					}, error => {
						commit('domainAddDnsRecords', { domain: payload.domain, data: new Error(error.response?.data?.error || error.response.statusText) })
						reject(new Error(error.response?.data?.error || error.response.statusText))
					})
				}
			})
		},
		loadDomainActivity ({ commit, state }, payload) {
			if (!state.domainActivity[payload.domain]) {
				domainsApi.get(`domain/${payload.domain}/activity`).then(({ data }) => {
					commit('domainAddActivity', { domain: payload.domain, data })
				}, error => {
					commit('domainAddActivity', { domain: payload.domain, data: new Error(error.response.data.error || error.response.statusText) })
				})
			}
		},
		loadDomainEmails ({ commit, state }, payload) {
			if (!state.domainEmails[payload.domain]) {
				const emailsApi = localStorage.getItem('beta-emails-api')

				if (emailsApi) {
					fetch(`${emailsApi}people/list-by-email-domain/${payload.domain}`)
					.then(response => response.json())
					.then((data) => {
						commit('domainAddEmails', { domain: payload.domain, data })
					}, error => {
						console.warn('[emails]', error)
						commit('domainAddEmails', { domain: payload.domain, data: new Error(error.response?.data?.error || error.response?.statusText) })
					})
				} else {
					commit('domainAddEmails', { domain: payload.domain, data: new Error('no emails API') })
				}
			}
		},
		loadDomainWebsiteInfo ({ commit, state }, payload) {
			if (state.domainData[payload.domain] && state.domainData[payload.domain].availability === 'registered') {
				fetch(`https://domains-api.com/domains/${payload.domain}/website-info?key=${process.env.VUE_APP_DOMAINS_API_KEY}`)
					.then(response => response.json())
					.then(data => {
						commit('domainAddWebsiteInfo', { domain: payload.domain, data })
					})
			}
		},
		loadIPsInfo({ dispatch }, payload) {
			uniq(payload).forEach(ip => dispatch('loadIPInfo', ip))
		},
		loadIPInfo({ commit, state }, payload) {
			if (state.authPerson && !(payload in state.ips)) {
				commit('ipSet', { ip: payload, loading: true })
				fetch(`https://web-api.com/ip-info/${payload}?key=${process.env.VUE_APP_WEB_API_KEY}`)
					.then(response => response.json())
					.then(ipInfo => {
						commit('ipSet', ipInfo)
					})
			}
		},

		updateStats({ commit }) {
			domainsApi.get(`stats/global`).then(({ data }) => {
				commit('setStats', data)
			})
		},

		loadPeople: ({ dispatch }, ids) => {
			uniq(ids.filter(Boolean)).forEach(id => {
				dispatch('loadPerson', id)
			})
		},
		loadPerson: ({ commit, state }, personId) => {
			if (!(personId in state.people)) {
				//state.people[personId] = {}
				return new Promise((resolve, reject) => {
					domainsApi
						.get(`people/${personId}`)
						.then(resp => {
							commit('addPerson', resp.data)
							resolve(resp)
						})
						.catch(reject)
				})
			}
		},
	},
	modules: {
	}
})
